@import '~normalize.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/inter-ui/inter.css';

html,
body {
  font-family: Inter, Roboto, Arial, sans-serif;
  margin: 0;
  height: 100%;
}

app-root {
  display: flex;
  flex-flow: column wrap;
  height: 100%;
}

.basic-container {
  padding: 5px;
}

mat-card.upload-screen {
  mat-card-header {
    flex-direction: column !important;
  }

  mat-card-header-text {
    margin: 0 !important;
  }

  .label {
    font-weight: 100;
  }
}

mat-card mat-card-actions {
  margin-left: 0;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #353d50;
  padding: 0 30px 30px;

  h1 {
    font-size: 24px;
    line-height: 32px;
    margin: 40px 0 15px;
  }

  .moreInfo {
    margin-bottom: 24px;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .title {
      color: #4e5666;
      font-size: 12px;
      line-height: 16px;
    }

    .username {
      color: #020d24;
      line-height: 24px;
    }

    .email {
      color: #020d24;
      font-size: 12px;
      line-height: 16px;
    }

    .button-template {
      border-radius: 20px;
      width: 200px;
      height: 50px;
      margin: auto 0;
      border: 2px solid #c8102e;
      background-color: white;
      color: black;
      font-weight: 500;
      margin-top: 10px;

      &:hover {
        color: #c8102e;
        font-weight: 500;
        background-color: #0000000a;
        cursor: pointer;
        box-shadow:
          rgb(0 0 0 / 10%) 0 20px 25px -5px,
          rgb(0 0 0 / 4%) 0 10px 10px -5px;
      }
    }
  }

  .file-upload {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
}

.g-bubble-container {
  display: flex;
  gap: 5px;
}

.g-bubble {
  background-color: white;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00437c;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.g-bubble:hover {
  background-color: #f1fafe;
  color: #00437c;
}

.g-bubble__active {
  background-color: #f1fafe;
  color: #00437c;
}

/* .g-bubble__disabled {
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
  color: 919191;
} */

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
mat-paginator {
  background: transparent !important;
}

.mat-mdc-paginator-range-actions {
  margin-right: 12px !important;
}

snack-bar-container.toast-snack-bar {
  background-color: #fff;
  font: inherit;
  color: inherit;
  font-size: inherit;
  max-width: 25em;
}

.toast-snack-bar-success {
  border: 1px #34bbaa solid;
  border-left: 1px solid rgb(52 187 170);
  border-left: 5px #34bbaa solid;

  .c-toastComponent__title,
  .c-toastComponent__icon {
    color: #34bbaa;
  }
}

mat-snack-bar-container.red-snackbar {
  .mdc-snackbar__surface {
    background-color: red !important;
    .mdc-snackbar__actions {
      button {
        color: white !important;
      }
    }
  }
}
mat-snack-bar-container.green-snackbar {
  .mdc-snackbar__surface {
    background-color: #63c163fc !important;

    .mdc-snackbar__actions {
      button {
        background: #00a000 !important;
        color: white !important;
      }
    }
  }
}
